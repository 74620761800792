import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { Section, SectionWrapper, Text } from "../../core/commonExports"

const BenefitItem = ({desc, icon}) => {
  let iconStyles = {borderRadius: "4px"}
  const getIcon = () => {
    switch (icon) {
      case "CashStolen": 
        return <StaticImage src="../../../assets/images/extra-benefits/cashStolen.webp" loading="lazy" alt="cash" className="icon" style={iconStyles} />
      case "LossOfDocs": 
        return <StaticImage src="../../../assets/images/extra-benefits/lossOfDocs.webp" loading="lazy" alt="lossOfDocs" className="icon" style={iconStyles} />
      case "AdditionalCoverPercent":
        return (
          <ExtraBenefitImgTag>
            <StaticImage src="../../../assets/images/extra-benefits/additionalCoverPercent.webp" loading="lazy" alt="additionalCover" className="icon" style={iconStyles} />
          </ExtraBenefitImgTag>
        )
      case "PersonalBelongings":
        return<StaticImage src="../../../assets/images/extra-benefits/personalBelongings.webp" loading="lazy" alt="cash" className="icon" style={iconStyles} />
      default:
        return;
    }
  }
  return (
    <ExtraBenefit>
      {getIcon()}
      <Text fontSize="18px" lineHeight="28px" mfontSize="14px" mlineHeight="24px" fontWeight="600" desktopStyles={{marginTop: "40px"}} mobileStyles={{marginTop: "20px"}}>
        {desc}
      </Text>
    </ExtraBenefit>
  )
}

const ExtraBenefits = ({
  pageName,
  desktopStyles={background: "#fff"}, 
  mobileStyles={background: "#F6F9FF"}
}) => {
  return (
    <>
      <Section desktopStyles={desktopStyles} mobileStyles={mobileStyles}>
        <SectionWrapper>
          <Text fontSize="32px" mfontSize="20px" lineHeight="38px" mlineHeight="32px" fontWeight="700" style={{textAlign: "center"}}>
            Get these FREE benefits with your policy!
          </Text>
          <BenefitsContainer>
            <BenefitItem icon="CashStolen" desc="Cash stolen or lost covered up to ₹50,000" />
            <BenefitItem icon="LossOfDocs" desc="Damage or loss of documents covered up to ₹50,000" />
            <BenefitItem icon="AdditionalCoverPercent" desc="Under Insurance Benefit - Additional 15% Sum Insured included" />
            <BenefitItem icon="PersonalBelongings" desc="Damage to personal belongings of up to ₹3 Lakhs covered" />
          </BenefitsContainer>
        </SectionWrapper>
      </Section>
    </>
  )
}

const ExtraBenefitImgTag = styled.div`
  border-radius: 10px;
  position: relative;
  margin: auto;
  &::after {
    content: "Exclusive";
    margin: auto;
    position: absolute;
    right: 0;
    left: 0;
    bottom: -24px;
    z-index: 1;
    text-align: center;
    width: fit-content;
    background: #FF934F;
    border-radius: 6px;
    color: #fff;
    padding: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
  @media screen and (max-width: 768px){
    width: 100px;
    &::after {
      font-size: 10px;
      line-height: 10px;
      padding: 4px;
      bottom: -8px;
    }
  }
`

const ExtraBenefit = styled.article`
  position: relative;
  width: 200px;
  text-align: center;
  @media (max-width: 768px) {
    max-width: 135px;
    .icon {
      width: 60px;
    }
  }
`

const BenefitsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  margin-top: 74px;
  @media (max-width: 768px) {
    width: min(500px, 100%);
    gap: 10px 20px;
    align-items: flex-start;
    margin: auto;
    margin-top: 36px;
  }
`

export default ExtraBenefits